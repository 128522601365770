import React from 'react';
import { ChromePicker } from 'react-color';

export default function UIConfig({ config, onUpdate }) {
  const positions = [
    { value: 'bottom-right', label: 'In basso a destra' },
    { value: 'bottom-left', label: 'In basso a sinistra' },
    { value: 'top-right', label: 'In alto a destra' },
    { value: 'top-left', label: 'In alto a sinistra' }
  ];

  const fonts = [
    { id: 'system-ui', label: 'System UI' },
    { id: 'arial', label: 'Arial' },
    { id: 'roboto', label: 'Roboto' },
    { id: 'inter', label: 'Inter' }
  ];

  const handleThemeUpdate = (key, value) => {
    onUpdate({
      theme: {
        ...config.theme,
        [key]: value
      }
    });
  };

  const handlePositionChange = (e) => {
    const value = e.target.value.toLowerCase().replace('_', '-');
    onUpdate({ position: value });
  };

  return (
    <div className="space-y-6">
      {/* Posizione Widget */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Posizione Widget
        </label>
        <div className="grid grid-cols-2 gap-3">
          {positions.map((pos) => (
            <button
              key={pos.value}
              onClick={() => handlePositionChange({ target: { value: pos.value } })}
              className={`p-3 text-sm rounded-lg border ${
                config.position === pos.value
                  ? 'border-blue-500 bg-blue-50 text-blue-700'
                  : 'border-gray-300 hover:border-gray-400'
              }`}
            >
              {pos.label}
            </button>
          ))}
        </div>
      </div>

      {/* Tema Colori */}
      <div className="space-y-4">
        <h3 className="text-sm font-medium text-gray-700">Tema Colori</h3>
        
        {/* Colore Primario */}
        <div>
          <label className="block text-sm text-gray-600 mb-2">
            Colore Primario
          </label>
          <ChromePicker
            color={config.theme.primary_color}
            onChange={(color) => handleThemeUpdate('primary_color', color.hex)}
            className="!w-full"
          />
        </div>

        {/* Colore Sfondo */}
        <div>
          <label className="block text-sm text-gray-600 mb-2">
            Colore Sfondo
          </label>
          <ChromePicker
            color={config.theme.background_color}
            onChange={(color) => handleThemeUpdate('background_color', color.hex)}
            className="!w-full"
          />
        </div>

        {/* Colore Testo */}
        <div>
          <label className="block text-sm text-gray-600 mb-2">
            Colore Testo
          </label>
          <ChromePicker
            color={config.theme.text_color}
            onChange={(color) => handleThemeUpdate('text_color', color.hex)}
            className="!w-full"
          />
        </div>

        {/* Font */}
        <div>
          <label className="block text-sm text-gray-600 mb-2">
            Font
          </label>
          <select
            value={config.theme.font_family}
            onChange={(e) => handleThemeUpdate('font_family', e.target.value)}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            {fonts.map((font) => (
              <option key={font.id} value={font.id}>
                {font.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Messaggi */}
      <div className="space-y-4">
        <h3 className="text-sm font-medium text-gray-700">Messaggi</h3>
        
        <div>
          <label className="block text-sm text-gray-600 mb-2">
            Messaggio di Benvenuto
          </label>
          <input
            type="text"
            value={config.welcome_message}
            onChange={(e) => onUpdate({ welcome_message: e.target.value })}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm text-gray-600 mb-2">
            Placeholder Input
          </label>
          <input
            type="text"
            value={config.placeholder_text}
            onChange={(e) => onUpdate({ placeholder_text: e.target.value })}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>
      </div>

      {/* CSS Personalizzato */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          CSS Personalizzato
        </label>
        <textarea
          value={config.custom_css}
          onChange={(e) => onUpdate({ custom_css: e.target.value })}
          rows={4}
          className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          placeholder=".chat-widget { /* il tuo CSS */ }"
        />
      </div>
    </div>
  );
} 