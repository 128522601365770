import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Dashboard from './Dashboard';
import Chatbot from './Chatbot';
import Login from './Login';
import ModelPlayground from './model-playground';
import EmbeddingsInterface from './EmbeddingsInterface';
import RAGSearchInterface from './components/rag/RAGSearchInterface';
import EnhancedRAGInterface from './components/rag/EnhancedRAGInterface';
import { Sun as SunIcon, Moon as MoonIcon } from 'lucide-react';
import APISettingsPanel from './components/settings/APISettingsPanel';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [currentView, setCurrentView] = useState('dashboard');
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme') === 'dark' || 
    (!localStorage.getItem('theme') && window.matchMedia('(prefers-color-scheme: dark)').matches)
  );

  useEffect(() => {
    if (token) {
      setIsLoggedIn(true);
    }
  }, [token]);

  useEffect(() => {
    localStorage.setItem('darkMode', isDarkMode);
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setCurrentView('dashboard');
  };

  const toggleView = () => {
    setCurrentView(current => {
      switch (current) {
        case 'dashboard':
          return 'chatbot';
        case 'chatbot':
          return 'playground';
        case 'playground':
          return 'embeddings';
        case 'embeddings':
          return 'rag-enhanced';
        case 'rag-enhanced':
          return 'settings';
        case 'settings':
          return 'dashboard';
        default:
          return 'dashboard';
      }
    });
  };

  const getToggleButtonText = () => {
    switch (currentView) {
      case 'dashboard':
        return 'Switch to Chatbot';
      case 'chatbot':
        return 'Switch to Playground';
      case 'playground':
        return 'Switch to Embeddings';
      case 'embeddings':
        return 'Switch to Enhanced RAG';
      case 'rag-enhanced':
        return 'Switch to Settings';
      case 'settings':
        return 'Switch to Dashboard';
      default:
        return 'Switch to Dashboard';
    }
  };

  if (!isLoggedIn) {
    return <Login setIsLoggedIn={setIsLoggedIn} setToken={setToken} isDarkMode={isDarkMode} />;
  }

  return (
    <BrowserRouter>
      <div className="min-h-screen transition-colors duration-200 dark:bg-gray-900 bg-gray-100">
        <nav className="transition-colors duration-200 dark:bg-gray-800 bg-white shadow-lg">
          <div className="max-w-6xl mx-auto px-4">
            <div className="flex justify-between">
              <div className="flex space-x-7">
                <div>
                  <a href="#" className="flex items-center py-4 px-2">
                    <span className="font-semibold text-lg text-indigo-600">FineTuningMax</span>
                  </a>
                </div>
              </div>

              <div className="flex items-center space-x-3">
                <button
                  onClick={toggleDarkMode}
                  className={`p-2 rounded-full transition-colors duration-200 ${
                    isDarkMode 
                      ? 'bg-gray-700 text-yellow-400 hover:bg-gray-600' 
                      : 'bg-gray-200 text-gray-600 hover:bg-gray-300'
                  }`}
                >
                  {isDarkMode ? (
                    <SunIcon className="h-5 w-5" />
                  ) : (
                    <MoonIcon className="h-5 w-5" />
                  )}
                </button>

                <button
                  onClick={toggleView}
                  className={`py-2 px-2 font-medium rounded transition-all duration-200 ${
                    isDarkMode 
                      ? 'text-gray-300 hover:bg-blue-600' 
                      : 'text-gray-500 hover:bg-blue-500 hover:text-white'
                  }`}
                >
                  {getToggleButtonText()}
                </button>

                <button
                  onClick={handleLogout}
                  className={`py-2 px-2 font-medium rounded transition-all duration-200 ${
                    isDarkMode 
                      ? 'text-gray-300 hover:bg-red-600' 
                      : 'text-gray-500 hover:bg-red-500 hover:text-white'
                  }`}
                >
                  Logout
                </button>

                <button onClick={() => setCurrentView('settings')}>
                  Impostazioni
                </button>
              </div>
            </div>
          </div>
        </nav>

        <div className="container mx-auto p-6">
          <div className="transition-colors duration-200 dark:bg-gray-800 bg-white rounded-lg shadow-lg p-6">
            {currentView === 'dashboard' ? (
              <Dashboard 
                isLoggedIn={isLoggedIn} 
                token={token} 
                onLogout={handleLogout}
                isDarkMode={isDarkMode}
              />
            ) : currentView === 'chatbot' ? (
              <Chatbot token={token} isDarkMode={isDarkMode} />
            ) : currentView === 'playground' ? (
              <ModelPlayground token={token} isDarkMode={isDarkMode} />
            ) : currentView === 'embeddings' ? (
              <EmbeddingsInterface 
                isDarkMode={isDarkMode}
                datasetId="your-dataset-id"
                onComplete={() => console.log('Analisi completata')}
              />
            ) : currentView === 'rag-simple' ? (
              <RAGSearchInterface
                modelId={null}
                isDarkMode={isDarkMode}
              />
            ) : currentView === 'settings' ? (
              <APISettingsPanel isDarkMode={isDarkMode} />
            ) : (
              <EnhancedRAGInterface
                isDarkMode={isDarkMode}
              />
            )}
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;