import React, { useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';
import ModelConfig from './tabs/ModelConfig';
import UIConfig from './tabs/UIConfig';
import SecurityConfig from './tabs/SecurityConfig';
import RAGConfig from './tabs/RAGConfig';
import WidgetPreview from './preview/WidgetPreview';
import CodePreview from './preview/CodePreview';

// Utility per gestire le classi CSS in modo condizionale
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// Funzione di normalizzazione degli ID dei documenti RAG
const normalizeRagDocuments = (docs) => {
  if (!docs) return [];
  if (Array.isArray(docs)) {
    return docs.map(doc => {
      if (typeof doc === 'object' && doc.id) {
        return String(doc.id);
      }
      return String(doc);
    });
  }
  return [];
};

export default function WidgetCustomizer({ playgroundConfig, onSave, token }) {
  // Sistema di logging avanzato per debugging
  const logState = (action, data) => {
    console.log(`[WidgetCustomizer][${action}]`, data);
  };

  logState('Initial Config', playgroundConfig);

  // Configurazione di default migliorata
  const defaultConfig = {
    // Configurazioni del modello
    model_name: playgroundConfig?.model || '',
    system_prompt: playgroundConfig?.systemPrompt || '',
    temperature: playgroundConfig?.temperature || 0.7,
    max_tokens: playgroundConfig?.maxTokens || 150,
    
    // Configurazioni RAG
    use_rag: playgroundConfig?.useRag || false,
    rag_prompt: playgroundConfig?.ragPrompt || '',
    rag_document_ids: normalizeRagDocuments(playgroundConfig?.activeRagDocuments),
    
    // Configurazioni UI
    name: 'Widget Personalizzato',
    position: 'bottom-right',
    theme: {
      primary_color: '#2563eb',
      background_color: '#ffffff',
      text_color: '#000000',
      font_family: 'system-ui'
    },
    custom_css: '',
    welcome_message: 'Ciao! Come posso aiutarti?',
    placeholder_text: 'Scrivi un messaggio...',
    allowed_domains: [],
    rate_limit: 60
  };

  // Stati principali
  const [activeTab, setActiveTab] = useState('ui');
  const [widgetConfig, setWidgetConfig] = useState(() => {
    if (!playgroundConfig) return defaultConfig;

    const normalizedDocIds = normalizeRagDocuments(
      playgroundConfig.useRag ? playgroundConfig.activeRagDocuments : []
    );

    logState('Initializing Widget Config', { 
      useRag: playgroundConfig.useRag,
      normalizedDocIds 
    });

    return {
      ...defaultConfig,
      model_name: playgroundConfig.model || defaultConfig.model_name,
      system_prompt: playgroundConfig.systemPrompt || defaultConfig.system_prompt,
      temperature: playgroundConfig.temperature || defaultConfig.temperature,
      max_tokens: playgroundConfig.maxTokens || defaultConfig.max_tokens,
      use_rag: playgroundConfig.useRag || defaultConfig.use_rag,
      rag_prompt: playgroundConfig.ragPrompt || defaultConfig.rag_prompt,
      rag_document_ids: normalizedDocIds,
    };
  });

  // Effetto per la sincronizzazione della configurazione
  useEffect(() => {
    if (playgroundConfig) {
      logState('Updating Config from Playground', playgroundConfig);

      const normalizedDocIds = normalizeRagDocuments(
        playgroundConfig.useRag ? playgroundConfig.activeRagDocuments : []
      );

      logState('Normalized Document IDs', normalizedDocIds);

      setWidgetConfig(prev => {
        const newConfig = {
          ...prev,
          model_name: playgroundConfig.model || prev.model_name,
          system_prompt: playgroundConfig.systemPrompt || prev.system_prompt,
          temperature: playgroundConfig.temperature || prev.temperature,
          max_tokens: playgroundConfig.maxTokens || prev.max_tokens,
          use_rag: playgroundConfig.useRag,
          rag_prompt: playgroundConfig.ragPrompt || prev.rag_prompt,
          rag_document_ids: normalizedDocIds,
        };

        logState('New Widget Config', newConfig);
        return newConfig;
      });
    }
  }, [playgroundConfig]);

  // Handler per gli aggiornamenti della configurazione
  const handleConfigUpdate = (values) => {
    logState('Config Update Requested', values);

    // Gestione speciale per gli aggiornamenti RAG
    if ('use_rag' in values) {
      values = {
        ...values,
        rag_document_ids: values.use_rag ? widgetConfig.rag_document_ids : []
      };
    }

    // Normalizzazione degli ID dei documenti se presenti
    if ('rag_document_ids' in values) {
      values.rag_document_ids = normalizeRagDocuments(values.rag_document_ids);
    }

    // Validazione della posizione se presente
    if (values.position) {
      const validPositions = ['bottom-right', 'bottom-left', 'top-right', 'top-left'];
      const normalized = values.position.toLowerCase().replace('_', '-');
      values.position = validPositions.includes(normalized) ? normalized : 'bottom-right';
    }

    setWidgetConfig(prev => {
      const newConfig = { ...prev, ...values };
      logState('New Config After Update', newConfig);
      return newConfig;
    });
  };

  // Tabs configuration
  const tabs = [
    { name: 'UI', component: UIConfig },
    { name: 'RAG', component: RAGConfig },
    { name: 'Sicurezza', component: SecurityConfig }
  ];

  // Validazione della configurazione
  if (!playgroundConfig || typeof playgroundConfig !== 'object' || !playgroundConfig.model) {
    return (
      <div className="p-6 text-center">
        <div className="text-red-600 mb-4">
          Configurazione non valida. Seleziona un modello e riprova.
        </div>
        <div className="text-sm text-gray-500">
          Assicurati di aver selezionato un modello prima di creare il widget.
        </div>
      </div>
    );
  }

  // Handler per il salvataggio
  const handleSave = () => {
    logState('Saving Configuration', widgetConfig);
    
    const configToSave = {
      // Configurazione del modello
      model_name: playgroundConfig.model,
      system_prompt: playgroundConfig.systemPrompt,
      temperature: Number(playgroundConfig.temperature),
      max_tokens: Number(playgroundConfig.maxTokens),
      
      // Configurazione RAG
      use_rag: playgroundConfig.useRag,
      rag_prompt: playgroundConfig.useRag ? playgroundConfig.ragPrompt : null,
      rag_document_ids: playgroundConfig.useRag ? normalizeRagDocuments(playgroundConfig.activeRagDocuments) : [],
      
      // Configurazione widget
      name: widgetConfig.name,
      position: widgetConfig.position.toLowerCase().replace('_', '-'),
      theme: {
        primary_color: widgetConfig.theme?.primary_color || "#2563eb",
        background_color: widgetConfig.theme?.background_color || "#ffffff",
        text_color: widgetConfig.theme?.text_color || "#000000",
        font_family: widgetConfig.theme?.font_family || "system-ui"
      },
      custom_css: widgetConfig.custom_css || "",
      welcome_message: widgetConfig.welcome_message,
      placeholder_text: widgetConfig.placeholder_text,
      allowed_domains: Array.isArray(widgetConfig.allowed_domains) ? widgetConfig.allowed_domains : [],
      rate_limit: Number(widgetConfig.rate_limit) || 60
    };

    logState('Final Config to Save', configToSave);
    onSave(configToSave);
  };

  return (
    <div className="flex flex-col h-full bg-gray-50">
      {/* Configurazione ereditata */}
      <div className="bg-gray-50 p-4 rounded-lg mb-4">
        <h3 className="font-semibold mb-2">Configurazione ereditata dal Playground:</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h4 className="font-medium text-sm mb-2">Modello e Parametri</h4>
            <ul className="text-sm space-y-1 text-gray-600">
              <li><span className="font-medium">Modello:</span> {playgroundConfig.model}</li>
              <li><span className="font-medium">Temperature:</span> {playgroundConfig.temperature}</li>
              <li><span className="font-medium">Max Tokens:</span> {playgroundConfig.maxTokens}</li>
            </ul>
          </div>
          <div>
            <h4 className="font-medium text-sm mb-2">Configurazione RAG</h4>
            <ul className="text-sm space-y-1 text-gray-600">
              <li>
                <span className="font-medium">RAG:</span> 
                {playgroundConfig.useRag ? 'Attivo' : 'Non attivo'}
              </li>
              <li>
                <span className="font-medium">Documenti:</span> 
                {playgroundConfig.activeRagDocuments?.length || 0}
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Tabs di configurazione */}
      <div className="flex-1 flex flex-col lg:flex-row gap-6 p-4 overflow-y-auto">
        <div className="lg:w-1/2">
          <Tab.Group onChange={setActiveTab}>
            <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
              {tabs.map((tab) => (
                <Tab
                  key={tab.name}
                  className={({ selected }) =>
                    classNames(
                      'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                      'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                      selected
                        ? 'bg-white shadow text-blue-700'
                        : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                    )
                  }
                >
                  {tab.name}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="mt-6">
              {tabs.map((tab, idx) => (
                <Tab.Panel
                  key={idx}
                  className="rounded-xl bg-white p-3"
                >
                  <tab.component 
                    config={widgetConfig}
                    onUpdate={handleConfigUpdate}
                    token={token}
                    isRagEnabled={widgetConfig.use_rag}
                    ragDocumentIds={widgetConfig.rag_document_ids}
                  />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>

        {/* Preview */}
        <div className="lg:w-1/2 space-y-6">
          <div className="bg-white rounded-lg shadow-sm p-4">
            <h3 className="text-lg font-semibold mb-4">Anteprima Widget</h3>
            <WidgetPreview config={widgetConfig} />
          </div>
          
          <div className="bg-white rounded-lg shadow-sm p-4">
            <h3 className="text-lg font-semibold mb-4">Codice di Integrazione</h3>
            <CodePreview config={widgetConfig} />
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="border-t bg-white p-4">
        <button
          onClick={handleSave}
          className="w-full py-2 px-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          Salva Widget
        </button>
      </div>
    </div>
  );
}