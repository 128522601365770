import React, { useState } from 'react';
import { Scatter } from 'react-chartjs-2';
import 'chart.js/auto';
import PropTypes from 'prop-types';
import { ChevronDown, ChevronUp, Info } from 'lucide-react';

// Funzione per generare colori HSL dinamicamente
const generateClusterColor = (index, total) => {
  // Usa HSL per distribuire i colori uniformemente nell'intero spettro
  const hue = (index * (360 / total)) % 360;
  return `hsla(${hue}, 70%, 60%, 0.7)`; // Saturazione e luminosità fisse per consistenza
};

const ClusteringAnalysis = ({ 
  clusters, 
  clusterSummaries, 
  isDarkMode,
  onPointClick 
}) => {
  const [expandedCluster, setExpandedCluster] = useState(null);

  // Configurazione del grafico a dispersione
  const generateChartData = () => {
    if (!clusters) return null;
    
    const totalClusters = Object.keys(clusters).length;
    
    return {
      datasets: Object.entries(clusters).map(([clusterId, documents]) => {
        const color = generateClusterColor(parseInt(clusterId), totalClusters);
        return {
          label: `Cluster ${clusterId}`,
          data: documents.map((doc) => ({
            x: doc.embedding_2d[0],
            y: doc.embedding_2d[1],
            meta: doc.metadata,
          })),
          backgroundColor: color,
          pointRadius: 6,
          pointHoverRadius: 8,
        };
      }),
    };
  };

  // Formatta i valori per la visualizzazione
  const formatValue = (value) => {
    if (typeof value === 'number') {
      return typeof value.toFixed === 'function' ? value.toFixed(2) : value.toString();
    }
    if (Array.isArray(value)) {
      return value.join(', ');
    }
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value);
    }
    return value;
  };

  // Raggruppa i campi del sommario per tipo
  const groupSummaryFields = (summary) => {
    const groups = {
      stats: {},      // Medie e totali
      common: {},     // Valori più comuni
      unique: {},     // Conteggi valori unici
      other: {}       // Altri campi
    };

    Object.entries(summary).forEach(([key, value]) => {
      if (key.startsWith('Media ') || key.startsWith('Totale ')) {
        groups.stats[key] = value;
      } else if (key.startsWith('Valore più comune ')) {
        groups.common[key] = value;
      } else if (key.startsWith('Valori unici ')) {
        groups.unique[key] = value;
      } else {
        groups.other[key] = value;
      }
    });

    return groups;
  };

  const getClusterLabel = (clusterId, summary) => {
    const mediaPrezzo = summary['Media Prezzo'] || 0;
    const mediaRating = summary['Media Rating_Medio'] || 0;
    const vendite = summary['Media Vendite_Mensili'] || 0;

    // Logica più sofisticata per determinare le etichette
    if (mediaPrezzo > 1000 && mediaRating >= 4.5) {
      return 'Premium Elite';
    } else if (mediaPrezzo > 800 && mediaRating >= 4.0) {
      return 'Premium';
    } else if (mediaPrezzo < 500 && vendite > 500) {
      return 'Best Seller Economico';
    } else if (mediaPrezzo < 500) {
      return 'Entry Level';
    } else if (vendite > 400) {
      return 'Best Seller Mid-Range';
    } else {
      return 'Mid-Range Standard';
    }
  };

  const getClusterDescription = (summary) => {
    const numElementi = summary['Numero di elementi'];
    const mediaPrezzo = summary['Media Prezzo'];
    const categoria = summary['Valore più comune Categoria'];
    const rating = summary['Media Rating_Medio'];
    const vendite = summary['Media Vendite_Mensili'];
    const descrizione = summary['Valore più comune Descrizione'];

    // Costruzione di una descrizione più dettagliata e significativa
    let description = `Cluster di ${numElementi} prodotti`;

    if (categoria) {
      description += ` nella categoria ${categoria}`;
    }

    if (mediaPrezzo) {
      const fascia = mediaPrezzo > 1000 ? 'fascia alta' : 
                     mediaPrezzo > 500 ? 'fascia media' : 'fascia economica';
      description += `, posizionati nella ${fascia} (€${mediaPrezzo.toFixed(2)})`;
    }

    if (rating) {
      const qualita = rating >= 4.5 ? 'eccellente' :
                      rating >= 4.0 ? 'molto buona' :
                      rating >= 3.5 ? 'buona' : 'media';
      description += ` con valutazione ${qualita} (${rating.toFixed(1)}/5)`;
    }

    if (vendite) {
      const performance = vendite > 500 ? 'ottime' :
                         vendite > 300 ? 'buone' :
                         vendite > 100 ? 'discrete' : 'moderate';
      description += ` e ${performance} vendite mensili (${vendite.toFixed(0)})`;
    }

    if (descrizione && descrizione.length < 50) {
      description += `. Caratteristica principale: ${descrizione}`;
    }

    return description;
  };

  return (
    <div className={`space-y-8 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
      {/* Header */}
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-semibold">Analisi Cluster</h2>
        <div className="flex items-center space-x-2">
          <Info size={20} className="text-gray-500" />
          <span className="text-sm text-gray-500">
            {Object.keys(clusterSummaries || {}).length} cluster identificati
          </span>
        </div>
      </div>

      {/* Scatter Plot */}
      <div className={`p-6 rounded-xl ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg`}>
        <h3 className="text-lg font-medium mb-4">Distribuzione dei Cluster</h3>
        <div className="h-96">
          <Scatter
            data={generateChartData()}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'top',
                  labels: {
                    color: isDarkMode ? 'white' : 'black',
                    padding: 20,
                    usePointStyle: true,
                    pointStyle: 'circle'
                  },
                },
                tooltip: {
                  backgroundColor: isDarkMode ? 'rgb(31, 41, 55)' : 'white',
                  titleColor: isDarkMode ? 'white' : 'black',
                  bodyColor: isDarkMode ? 'white' : 'black',
                  borderColor: isDarkMode ? 'rgb(75, 85, 99)' : 'rgb(229, 231, 235)',
                  borderWidth: 1,
                  padding: 12,
                  callbacks: {
                    label: function(context) {
                      const point = context.raw;
                      const metadata = point.meta || {};
                      return Object.entries(metadata).map(([key, value]) => 
                        `${key}: ${formatValue(value)}`
                      );
                    },
                  },
                },
              },
              scales: {
                x: {
                  grid: {
                    color: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
                  },
                  ticks: {
                    color: isDarkMode ? 'white' : 'black',
                  },
                },
                y: {
                  grid: {
                    color: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
                  },
                  ticks: {
                    color: isDarkMode ? 'white' : 'black',
                  },
                },
              },
              onClick: (event, elements) => {
                if (elements.length > 0 && onPointClick) {
                  const element = elements[0];
                  const datasetIndex = element.datasetIndex;
                  const index = element.index;
                  const point = generateChartData().datasets[datasetIndex].data[index];
                  onPointClick(point);
                }
              },
            }}
          />
        </div>
      </div>

      {/* Cluster Cards */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {Object.entries(clusterSummaries || {}).map(([clusterId, summary]) => {
          const groups = groupSummaryFields(summary);
          const isExpanded = expandedCluster === clusterId;
          const clusterLabel = getClusterLabel(clusterId, summary);
          const description = getClusterDescription(summary);

          // Determina il colore del badge in base al tipo di cluster
          const getBadgeColor = (label) => {
            switch (label) {
              case 'Premium Elite':
                return 'bg-purple-600 text-white';
              case 'Premium':
                return 'bg-indigo-600 text-white';
              case 'Best Seller Economico':
                return 'bg-green-600 text-white';
              case 'Entry Level':
                return 'bg-blue-600 text-white';
              default:
                return 'bg-gray-600 text-white';
            }
          };

          // Formatta il valore con icona appropriata
          const getValueWithIcon = (key, value) => {
            if (key.toLowerCase().includes('prezzo')) {
              return (
                <div className="flex items-center justify-center gap-1 w-full">
                  <span className="text-base">€</span>
                  <span className="text-sm font-semibold">{value.toFixed(2)}</span>
                </div>
              );
            }
            if (key.toLowerCase().includes('rating')) {
              return (
                <div className="flex items-center justify-center gap-1 w-full">
                  <span className="text-sm font-semibold">{value.toFixed(1)}</span>
                  <span className="text-sm text-yellow-400">★</span>
                </div>
              );
            }
            if (key.toLowerCase().includes('vendite')) {
              return (
                <div className="flex flex-col items-center justify-center w-full">
                  <span className="text-sm font-semibold">{value.toFixed(0)}</span>
                  <span className="text-[10px] text-gray-500">unità/mese</span>
                </div>
              );
            }
            return <span className="text-sm font-semibold text-center w-full">{formatValue(value)}</span>;
          };

          return (
            <div
              key={clusterId}
              className={`rounded-xl shadow-lg overflow-hidden transition-all duration-200 ${
                isDarkMode ? 'bg-gray-800 border border-gray-700' : 'bg-white'
              }`}
            >
              {/* Header del cluster */}
              <div 
                className={`px-6 py-4 cursor-pointer ${
                  isDarkMode ? 'bg-gray-800' : 'bg-gray-50'
                }`}
                onClick={() => setExpandedCluster(isExpanded ? null : clusterId)}
              >
                <div className="flex justify-between items-start">
                  <div className="space-y-3">
                    <div className="flex items-center gap-3">
                      <h3 
                        className="text-2xl font-bold" 
                        style={{ 
                          color: generateClusterColor(parseInt(clusterId), Object.keys(clusterSummaries).length)
                            .replace('0.7', '1') // Rimuove la trasparenza per il testo
                        }}
                      >
                        Cluster {clusterId}
                      </h3>
                      <span className={`px-4 py-1.5 rounded-full text-sm font-semibold ${getBadgeColor(clusterLabel)}`}>
                        {clusterLabel}
                      </span>
                    </div>
                    <p className="text-base text-gray-300 leading-relaxed max-w-2xl">
                      {description}
                    </p>
                  </div>
                  {isExpanded ? <ChevronUp size={28} /> : <ChevronDown size={28} />}
                </div>
              </div>

              {/* Contenuto espanso */}
              <div className={`overflow-hidden transition-all duration-300 ${
                isExpanded ? 'max-h-screen' : 'max-h-0'
              }`}>
                <div className="p-6 space-y-6 w-full max-w-[900px] mx-auto">
                  {/* Metriche Chiave */}
                  <div>
                    <h4 className="text-base font-semibold mb-4">Metriche Chiave</h4>
                    <div className="bg-gray-700/50 rounded-lg p-4">
                      <div className="flex flex-row items-center justify-between gap-4">
                        {/* Prezzo */}
                        <div className="flex-1 flex flex-col items-center border-r border-gray-600 pr-4">
                          <span className="text-xs text-gray-400 mb-1">Prezzo Medio</span>
                          <div className="flex items-baseline">
                            <span className="text-lg">€</span>
                            <span className="text-xl font-semibold ml-1">{summary['Media Prezzo']?.toFixed(2)}</span>
                          </div>
                        </div>

                        {/* Rating */}
                        <div className="flex-1 flex flex-col items-center border-r border-gray-600 pr-4">
                          <span className="text-xs text-gray-400 mb-1">Rating</span>
                          <div className="flex items-center">
                            <span className="text-xl font-semibold">{summary['Media Rating_Medio']?.toFixed(1)}</span>
                            <span className="text-lg text-yellow-400 ml-1">★</span>
                          </div>
                        </div>

                        {/* Vendite */}
                        <div className="flex-1 flex flex-col items-center">
                          <span className="text-xs text-gray-400 mb-1">Vendite Mensili</span>
                          <div className="flex flex-col items-center">
                            <span className="text-xl font-semibold">{summary['Media Vendite_Mensili']?.toFixed(0)}</span>
                            <span className="text-[10px] text-gray-500">unità</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Caratteristiche Principali con nuovo stile */}
                    {Object.keys(groups.common).length > 0 && (
                      <div className="mt-6">
                        <h4 className="text-base font-semibold mb-4">Caratteristiche Principali</h4>
                        <div className="grid grid-cols-2 gap-4">
                          {Object.entries(groups.common).map(([key, value]) => (
                            <div 
                              key={key}
                              className={`p-4 rounded-lg ${
                                isDarkMode ? 'bg-gray-700/50' : 'bg-gray-50'
                              }`}
                            >
                              <div className="text-xs text-gray-400 mb-2">
                                {key.replace('Valore più comune ', '')}
                              </div>
                              <div className="text-sm font-medium">
                                {value}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

ClusteringAnalysis.propTypes = {
  clusters: PropTypes.object.isRequired,
  clusterSummaries: PropTypes.object.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  onPointClick: PropTypes.func,
};

export default ClusteringAnalysis;