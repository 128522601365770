import React, { useState, useEffect } from 'react';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';

export default function WidgetPreview({ config }) {
  const [isOpen, setIsOpen] = useState(true);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');

  // Aggiorna i messaggi quando cambia il welcome_message
  useEffect(() => {
    if (config?.welcome_message) {
      setMessages([{ role: 'assistant', content: config.welcome_message }]);
    }
  }, [config?.welcome_message]);

  if (!config) return null;

  // Stili dinamici basati sulla configurazione
  const widgetStyle = {
    fontFamily: config.theme.font_family,
    '--primary-color': config.theme.primary_color,
    '--bg-color': config.theme.background_color,
    '--text-color': config.theme.text_color,
  };

  // Posizionamento del widget
  const positionClasses = {
    'bottom-right': 'bottom-0 right-0',
    'bottom-left': 'bottom-0 left-0',
    'top-right': 'top-0 right-0',
    'top-left': 'top-0 left-0'
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!inputMessage.trim()) return;

    setMessages(prev => [
      ...prev,
      { role: 'user', content: inputMessage }
    ]);

    setTimeout(() => {
      setMessages(prev => [
        ...prev,
        { 
          role: 'assistant', 
          content: 'Questa è una preview del widget. Le risposte reali verranno generate dal modello selezionato.' 
        }
      ]);
    }, 1000);

    setInputMessage('');
  };

  return (
    <div 
      className={`fixed ${positionClasses[config.position]} m-4 max-w-sm w-full shadow-xl rounded-lg overflow-hidden`}
      style={widgetStyle}
    >
      {/* Header */}
      <div 
        className="p-4 bg-[var(--primary-color)] text-white flex justify-between items-center"
      >
        <h3 className="font-medium">Chat</h3>
        <button 
          onClick={() => setIsOpen(!isOpen)}
          className="p-1 hover:bg-white/10 rounded"
        >
          {isOpen ? '−' : '+'}
        </button>
      </div>

      {isOpen && (
        <>
          {/* Chat Messages */}
          <div 
            className="h-96 overflow-y-auto p-4 bg-[var(--bg-color)]"
            style={{ color: 'var(--text-color)' }}
          >
            {messages.map((message, index) => (
              <div
                key={index}
                className={`mb-4 ${
                  message.role === 'user' 
                    ? 'ml-auto text-right' 
                    : 'mr-auto'
                }`}
              >
                <div
                  className={`inline-block p-3 rounded-lg ${
                    message.role === 'user'
                      ? 'bg-[var(--primary-color)] text-white'
                      : 'bg-gray-100 text-gray-800'
                  }`}
                >
                  {message.content}
                </div>
              </div>
            ))}
          </div>

          {/* Input Form */}
          <form 
            onSubmit={handleSubmit}
            className="p-4 bg-[var(--bg-color)] border-t"
          >
            <div className="flex space-x-2">
              <input
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                placeholder={config.placeholder_text}
                className="flex-1 px-3 py-2 border rounded-lg focus:outline-none focus:border-[var(--primary-color)]"
              />
              <button
                type="submit"
                className="p-2 bg-[var(--primary-color)] text-white rounded-lg hover:opacity-90"
              >
                <PaperAirplaneIcon className="h-5 w-5" />
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
} 