import React, { useState } from 'react';
import axios from 'axios';
import { Search, Upload, AlertCircle } from 'lucide-react';

const API_URL = 'https://www.finetuningmax.com';

const RAGSearchInterface = ({ isDarkMode }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  // Gestione upload file
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    setIsLoading(true);
    setError(null);
    setUploadProgress(0);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${API_URL}/api/rag/upload`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setUploadProgress(Math.round(progress));
          }
        }
      );

      if (response.data.success) {
        setError(null);
        alert('Documento caricato con successo!');
      }
    } catch (error) {
      console.error('Error uploading RAG document:', error);
      setError(error.response?.data?.detail || 'Errore nel caricamento del documento');
    } finally {
      setIsLoading(false);
      setUploadProgress(0);
    }
  };

  // Gestione ricerca
  const handleSearch = async () => {
    if (!query.trim()) return;

    setIsLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${API_URL}/api/rag/search`,
        {
          params: { query: query },
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );

      if (response.data.success) {
        setResults(response.data.results);
      }
    } catch (error) {
      console.error('Error in RAG search:', error);
      setError(error.response?.data?.detail || 'Errore nella ricerca');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`p-6 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
      <h2 className="text-2xl font-bold mb-6">RAG Search Interface</h2>

      {/* Upload Section */}
      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-4">Carica Documenti</h3>
        <div className="flex items-center space-x-4">
          <label className={`
            cursor-pointer px-4 py-2 rounded-lg
            ${isDarkMode 
              ? 'bg-blue-600 hover:bg-blue-700' 
              : 'bg-blue-500 hover:bg-blue-600'
            } text-white transition-colors duration-200
          `}>
            <Upload className="inline-block mr-2 h-5 w-5" />
            Carica File
            <input
              type="file"
              className="hidden"
              onChange={handleFileUpload}
              accept=".pdf,.txt,.doc,.docx"
            />
          </label>
          {uploadProgress > 0 && (
            <div className="flex items-center">
              <div className="w-48 h-2 bg-gray-200 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-green-500 transition-all duration-300"
                  style={{ width: `${uploadProgress}%` }}
                />
              </div>
              <span className="ml-2">{uploadProgress}%</span>
            </div>
          )}
        </div>
      </div>

      {/* Search Section */}
      <div className="mb-8">
        <div className="flex space-x-4 mb-4">
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Inserisci la tua domanda..."
            className={`
              flex-1 p-2 rounded-lg border
              ${isDarkMode 
                ? 'bg-gray-700 border-gray-600 text-white' 
                : 'bg-white border-gray-300'
              } transition-colors duration-200
            `}
          />
          <button
            onClick={handleSearch}
            disabled={isLoading}
            className={`
              px-4 py-2 rounded-lg flex items-center
              ${isDarkMode 
                ? 'bg-green-600 hover:bg-green-700' 
                : 'bg-green-500 hover:bg-green-600'
              } text-white transition-colors duration-200
              disabled:opacity-50 disabled:cursor-not-allowed
            `}
          >
            <Search className="h-5 w-5 mr-2" />
            Cerca
          </button>
        </div>

        {/* Results Section */}
        {results.length > 0 && (
          <div className="space-y-4">
            {results.map((result, index) => (
              <div
                key={index}
                className={`p-4 rounded-lg ${
                  isDarkMode ? 'bg-gray-700' : 'bg-gray-50'
                }`}
              >
                <div className="mb-2">{result.text}</div>
                <div className="text-sm text-gray-500">
                  Similarità: {(result.similarity * 100).toFixed(1)}%
                </div>
                {result.metadata && (
                  <div className="text-sm text-gray-500">
                    Fonte: {result.metadata.source}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {/* Error Message */}
        {error && (
          <div className="flex items-center space-x-2 text-red-500 mt-4">
            <AlertCircle className="h-5 w-5" />
            <span>{error}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default RAGSearchInterface; 