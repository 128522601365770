import React, { useState, useEffect } from 'react';
import { Key, Save, AlertCircle, Eye, EyeOff } from 'lucide-react';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';

const APISettingsPanel = ({ isDarkMode }) => {
  const [useCustomAPI, setUseCustomAPI] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [hasStoredKey, setHasStoredKey] = useState(false);
  const [showStoredKey, setShowStoredKey] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const loadSettings = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${API_BASE_URL}/settings/api-credentials`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        console.log("API Settings loaded:", data); // Debug log
        setUseCustomAPI(data.use_custom_api);
        setHasStoredKey(data.has_api_key);
        
        // Se c'è una chiave salvata, impostiamo useCustomAPI a true
        if (data.has_api_key) {
          setUseCustomAPI(true);
        }
      }
    } catch (error) {
      console.error('Errore nel caricamento delle impostazioni:', error);
      setMessage({ type: 'error', text: 'Errore nel caricamento delle impostazioni' });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadSettings();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    try {
      const response = await fetch(`${API_BASE_URL}/settings/api-credentials`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          use_custom_api: useCustomAPI,
          api_key: apiKey
        })
      });

      if (response.ok) {
        setMessage({ type: 'success', text: 'Impostazioni API salvate con successo' });
        setHasStoredKey(true);
        setApiKey(''); // Pulisce il campo input
        await loadSettings(); // Ricarica le impostazioni
      } else {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Errore nel salvataggio delle impostazioni');
      }
    } catch (error) {
      setMessage({ type: 'error', text: error.message });
    } finally {
      setIsSaving(false);
    }
  };

  const renderStoredKeyIndicator = () => {
    if (!hasStoredKey) return null;

    return (
      <div className={`mt-2 p-3 rounded ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
        <div className="flex items-center justify-between">
          <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>
            API Key salvata: {showStoredKey ? 'sk-...XXXX' : '••••••••••••••••'}
          </span>
          <button
            type="button"
            onClick={() => setShowStoredKey(!showStoredKey)}
            className={`p-1 rounded hover:bg-gray-600 transition-colors`}
          >
            {showStoredKey ? <EyeOff size={16} /> : <Eye size={16} />}
          </button>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className={`p-6 rounded-lg shadow-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
        <p className={isDarkMode ? 'text-white' : 'text-gray-800'}>Caricamento impostazioni...</p>
      </div>
    );
  }

  return (
    <div className={`p-6 rounded-lg shadow-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
      <h2 className={`text-2xl font-bold mb-6 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
        Impostazioni API
      </h2>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="flex items-center space-x-3">
            <input
              type="checkbox"
              checked={useCustomAPI}
              onChange={(e) => {
                setUseCustomAPI(e.target.checked);
                if (!e.target.checked) {
                  setApiKey('');
                  setHasStoredKey(false);
                }
              }}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <span className={isDarkMode ? 'text-white' : 'text-gray-700'}>
              Usa la mia API Key di OpenAI
            </span>
          </label>
        </div>

        {useCustomAPI && (
          <div className="space-y-2">
            <label className={`block ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>
              OpenAI API Key
            </label>
            {renderStoredKeyIndicator()}
            <input
              type="password"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              className={`w-full p-2 border rounded ${
                isDarkMode 
                  ? 'bg-gray-700 border-gray-600 text-white' 
                  : 'bg-white border-gray-300'
              }`}
              placeholder="Inserisci una nuova API key..."
            />
          </div>
        )}

        {message && (
          <div className={`p-4 rounded ${
            message.type === 'success' 
              ? 'bg-green-100 text-green-700' 
              : 'bg-red-100 text-red-700'
          }`}>
            {message.text}
          </div>
        )}

        <button
          type="submit"
          disabled={isSaving}
          className={`w-full py-2 px-4 rounded ${
            isDarkMode 
              ? 'bg-blue-600 hover:bg-blue-700' 
              : 'bg-blue-500 hover:bg-blue-600'
          } text-white font-medium`}
        >
          {isSaving ? 'Salvataggio...' : 'Salva Impostazioni'}
        </button>
      </form>
    </div>
  );
};

export default APISettingsPanel; 