import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';

const Login = ({ setIsLoggedIn, setToken }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isNewUser, setIsNewUser] = useState(true);
  const [loginMethod, setLoginMethod] = useState('magic'); // 'magic' o 'password'

  // Verifica il token appena il componente viene montato
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    
    if (token) {
      console.log('Token trovato, avvio verifica...');
      verifyMagicLink(token);
    }
  }, []);

  const verifyMagicLink = async (token) => {
    try {
      setLoading(true);
      setError('');
      
      const response = await axios.get(`${API_BASE_URL}/auth/magic-link/verify`, {
        params: { token }
      });

      if (response.data?.access_token) {
        setToken(response.data.access_token);
        setIsLoggedIn(true);
        localStorage.setItem('token', response.data.access_token);
        window.history.replaceState({}, document.title, '/');
      } else {
        throw new Error('Token di accesso non trovato nella risposta');
      }
    } catch (error) {
      if (error.response?.status === 403) {
        setError(
          <div>
            <p className="font-medium">Account non ancora attivo</p>
            <p className="mt-2">
              Il tuo account è in attesa di attivazione. 
              Ti contatteremo presto per completare la procedura di registrazione.
            </p>
            <p className="mt-2">
              Per qualsiasi domanda, contatta il nostro supporto.
            </p>
          </div>
        );
      } else {
        setError(
          error.response?.data?.detail || 
          error.message ||
          'Errore durante la verifica del link. Richiedi un nuovo link di accesso.'
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleMagicLinkRequest = async () => {
    try {
      setLoading(true);
      setError('');
      
      await axios.post(`${API_BASE_URL}/auth/magic-link/request`, {
        email: email,
        full_name: fullName
      });
      
      setMagicLinkSent(true);
    } catch (error) {
      setError(error.response?.data?.detail || 'Errore nell\'invio del magic link. Riprova.');
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordLogin = async () => {
    try {
      setLoading(true);
      setError('');
      
      const formData = new FormData();
      formData.append('username', email);
      formData.append('password', password);

      const response = await axios.post(`${API_BASE_URL}/auth/token`, formData);

      if (response.data?.access_token) {
        setToken(response.data.access_token);
        setIsLoggedIn(true);
        localStorage.setItem('token', response.data.access_token);
      } else {
        throw new Error('Token di accesso non trovato nella risposta');
      }
    } catch (error) {
      setError(error.response?.data?.detail || 'Credenziali non valide');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="bg-white shadow-md rounded-lg p-8 max-w-sm w-full text-center">
          <p className="text-gray-600">Verifica in corso...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white shadow-md rounded-lg p-8 max-w-sm w-full">
        <div className="flex justify-center mb-6 bg-gray-100 rounded-lg p-1">
          <button
            onClick={() => setIsNewUser(true)}
            className={`px-4 py-2 rounded-lg transition-colors duration-200 ${
              isNewUser 
                ? 'bg-white shadow-sm text-indigo-600' 
                : 'text-gray-500 hover:text-gray-700'
            }`}
          >
            Registrati
          </button>
          <button
            onClick={() => setIsNewUser(false)}
            className={`px-4 py-2 rounded-lg transition-colors duration-200 ${
              !isNewUser 
                ? 'bg-white shadow-sm text-indigo-600' 
                : 'text-gray-500 hover:text-gray-700'
            }`}
          >
            Accedi
          </button>
        </div>

        <h2 className="text-2xl font-bold text-gray-900 mb-6 text-center">
          {isNewUser ? 'Registrati' : 'Accedi'}
        </h2>

        {!isNewUser && (
          <div className="flex justify-center mb-4 space-x-4">
            <button
              onClick={() => setLoginMethod('magic')}
              className={`px-4 py-2 rounded ${
                loginMethod === 'magic' 
                  ? 'bg-indigo-100 text-indigo-700' 
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              Magic Link
            </button>
            <button
              onClick={() => setLoginMethod('password')}
              className={`px-4 py-2 rounded ${
                loginMethod === 'password' 
                  ? 'bg-indigo-100 text-indigo-700' 
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              Password
            </button>
          </div>
        )}

        {!magicLinkSent ? (
          <div className="space-y-4">
            {isNewUser && (
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Nome e Cognome
                </label>
                <input
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Mario Rossi"
                  disabled={loading}
                />
              </div>
            )}
            
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="nome@azienda.com"
                disabled={loading}
              />
            </div>

            {(!isNewUser && loginMethod === 'password') && (
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  disabled={loading}
                />
              </div>
            )}

            <button
              onClick={!isNewUser && loginMethod === 'password' ? handlePasswordLogin : handleMagicLinkRequest}
              disabled={loading || !email || (isNewUser && !fullName) || (loginMethod === 'password' && !password)}
              className="w-full py-2 px-4 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? 'Elaborazione...' : (
                !isNewUser && loginMethod === 'password' ? 'Accedi' : 'Invia link di accesso'
              )}
            </button>

            <p className="text-sm text-gray-500 text-center mt-4">
              {isNewUser 
                ? 'Riceverai un link di accesso via email per completare la registrazione.'
                : loginMethod === 'magic'
                  ? 'Riceverai un link di accesso via email per accedere al tuo account.'
                  : 'Inserisci le tue credenziali per accedere.'}
            </p>
          </div>
        ) : (
          <div className="text-center">
            <p className="text-green-600">
              Link di accesso inviato! Controlla la tua email.
            </p>
          </div>
        )}

        {error && (
          <div className="mt-4 p-4 bg-red-50 rounded-md">
            <div className="text-sm text-red-600">
              {typeof error === 'string' ? (
                <p className="text-center">{error}</p>
              ) : (
                error
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;


