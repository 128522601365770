import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { Search, Upload, AlertCircle, Trash2, X } from 'lucide-react';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';

const DocumentList = ({ documents, isDarkMode }) => {
  return (
    <div className="space-y-4">
      <h3 className={`text-lg font-semibold ${
        isDarkMode ? 'text-white' : 'text-gray-900'
      }`}>
        Documenti Disponibili
      </h3>
      <div className="grid gap-4">
        {documents.map((doc) => (
          <div 
            key={doc.id}
            className={`p-4 rounded-lg border transition-colors duration-200 ${
              isDarkMode 
                ? 'bg-gray-800 border-gray-700' 
                : 'bg-white border-gray-200'
            }`}
          >
            <div className="flex justify-between items-start">
              <div>
                <h4 className={`font-medium ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {doc.file_name}
                </h4>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Categoria: {doc.category || 'Non specificata'}
                </p>
              </div>
              <span className={`text-xs px-2 py-1 rounded-full ${
                doc.status === 'completed'
                  ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                  : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200'
              }`}>
                {doc.status}
              </span>
            </div>
            {doc.description && (
              <p className={`mt-2 text-sm ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                {doc.description}
              </p>
            )}
            <div className="mt-2 flex items-center justify-between text-xs">
              <span className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
                {new Date(doc.created_at).toLocaleDateString()}
              </span>
              <span className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
                {(doc.file_size / 1024).toFixed(1)} KB
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const EnhancedRAGInterface = ({ isDarkMode }) => {
  // Stati base
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [documents, setDocuments] = useState([]);

  // Definisci loadDocuments fuori da useEffect
  const loadDocuments = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${API_BASE_URL}/rag/documents`,
        { 
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
          }
        }
      );
      
      if (response.data) {
        setDocuments(response.data);
      }
    } catch (error) {
      console.error('Error loading documents:', error);
    }
  };

  // Carica la lista dei documenti all'avvio
  useEffect(() => {
    loadDocuments();
  }, []);

  // Gestione upload file
  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) return;

    setIsLoading(true);
    setError(null);
    
    const formData = new FormData();
    formData.append('file', file);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${API_BASE_URL}/rag/upload`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (response.data.success) {
        await loadDocuments();
        setError(null);
      }
    } catch (error) {
      console.error('Error uploading document:', error);
      const errorMessage = error.response?.data?.detail || 'Errore nel caricamento del documento';
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, []);  // Nota: non serve includere loadDocuments nelle dipendenze perché è una funzione del componente

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'text/plain': ['.txt'],
      'text/csv': ['.csv']
    }
  });

  // Elimina un documento
  const handleDeleteDocument = async (documentId) => {
    if (!window.confirm('Sei sicuro di voler eliminare questo documento?')) {
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('token');
      await axios.delete(
        `${API_BASE_URL}/rag/documents/${documentId}`,
        { 
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
          }
        }
      );
      
      // Ricarica la lista dei documenti
      await loadDocuments();
      setError(null);
    } catch (error) {
      console.error('Error deleting document:', error);
      const errorMessage = error.response?.data?.detail || 'Errore nell\'eliminazione del documento';
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  // Gestione ricerca
  const handleSearch = async () => {
    if (!query.trim()) return;

    setIsLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${API_BASE_URL}/rag/search`,
        {
          params: { query: query },
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );

      if (response.data.success) {
        setResults(response.data.results);
      }
    } catch (error) {
      setError(error.response?.data?.detail || 'Errore nella ricerca');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`p-6 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
      <h2 className="text-2xl font-bold mb-6">RAG Search Interface</h2>

      {/* Upload Section con Drag & Drop */}
      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-4">Carica Documenti</h3>
        <div
          {...getRootProps()}
          className={`
            border-2 border-dashed rounded-lg p-6 text-center cursor-pointer
            transition-colors duration-200
            ${isDarkMode 
              ? 'border-gray-600 hover:border-blue-500' 
              : 'border-gray-300 hover:border-blue-400'}
            ${isDragActive ? 'border-blue-500 bg-blue-50' : ''}
          `}
        >
          <input {...getInputProps()} />
          <Upload className="mx-auto h-12 w-12 mb-4 text-gray-400" />
          {isDragActive ? (
            <p>Rilascia i file qui...</p>
          ) : (
            <p>Trascina i file qui o clicca per selezionarli</p>
          )}
          <p className="text-sm text-gray-500 mt-2">
            Supporta PDF, DOCX, TXT, CSV
          </p>
        </div>
      </div>

      {/* Documents List Section */}
      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-4">Documenti Caricati</h3>
        <div className="space-y-4">
          {documents.map((doc) => (
            <div
              key={doc.id}
              className={`
                p-4 rounded-lg flex items-center justify-between
                ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}
              `}
            >
              <div className="flex-1">
                <div className="font-medium">{doc.file_name}</div>
                <div className="text-sm text-gray-500">
                  {new Date(doc.created_at).toLocaleString()}
                </div>
                <div className={`
                  text-sm mt-1 
                  ${doc.status === 'completed' ? 'text-green-500' : 
                    doc.status === 'failed' ? 'text-red-500' : 'text-yellow-500'}
                `}>
                  {doc.status === 'completed' ? 'Elaborato' :
                   doc.status === 'failed' ? 'Errore' : 'In elaborazione'}
                </div>
              </div>
              
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => handleDeleteDocument(doc.id)}
                  className={`
                    p-2 rounded-full hover:bg-gray-200 
                    ${isDarkMode ? 'hover:bg-gray-600' : 'hover:bg-gray-200'}
                    transition-colors duration-200
                  `}
                  title="Elimina documento"
                >
                  <Trash2 className="h-5 w-5 text-red-500" />
                </button>
              </div>
            </div>
          ))}
          
          {documents.length === 0 && (
            <div className={`
              text-center p-4 rounded-lg
              ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}
            `}>
              Nessun documento caricato
            </div>
          )}
        </div>
      </div>

      {/* Search Section */}
      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-4">Cerca nei Documenti</h3>
        <div className="flex space-x-4">
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
            placeholder="Inserisci la tua domanda..."
            className={`
              flex-1 p-2 rounded-lg border
              ${isDarkMode 
                ? 'bg-gray-700 border-gray-600 text-white' 
                : 'bg-white border-gray-300'}
              transition-colors duration-200
            `}
          />
          <button
            onClick={handleSearch}
            disabled={isLoading}
            className={`
              px-4 py-2 rounded-lg flex items-center
              ${isDarkMode 
                ? 'bg-green-600 hover:bg-green-700' 
                : 'bg-green-500 hover:bg-green-600'}
              text-white transition-colors duration-200
              disabled:opacity-50 disabled:cursor-not-allowed
            `}
          >
            <Search className="h-5 w-5 mr-2" />
            Cerca
          </button>
        </div>

        {/* Results Section */}
        {results.length > 0 && (
          <div className="space-y-4">
            {results.map((result, index) => (
              <div
                key={index}
                className={`p-4 rounded-lg ${
                  isDarkMode ? 'bg-gray-700' : 'bg-gray-50'
                }`}
              >
                <div className="mb-2">{result.text}</div>
                <div className="text-sm text-gray-500">
                  Similarità: {(result.similarity * 100).toFixed(1)}%
                </div>
                {result.metadata && (
                  <div className="text-sm text-gray-500">
                    Fonte: {result.metadata.source}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {/* Error Message */}
        {error && (
          <div className="flex items-center space-x-2 text-red-500 mt-4">
            <AlertCircle className="h-5 w-5" />
            <span>{error}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default EnhancedRAGInterface;